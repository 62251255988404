import { FormattedMessage, injectIntl } from "react-intl";
import React from "react";
import { Table } from "react-bootstrap";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { getList } from "api/medicines";
import { logToSentry } from "_metronic/_helpers/SentryHelper";
import IPatientMedicinesDataInNotices from "../interfaces/IPatientMedicinesDataInNotices";
import { convertDateTimeToTimeNoSeconds } from "_metronic/_helpers";
import { useHistory } from "react-router-dom";
import { on } from "events";

const SOTableHeader = () => {
  return (
    <TableHead>
      <TableRow className="text-left">
        <TableCell style={{ width: "30%", fontSize: '10px' }} className="pl-0"><FormattedMessage id="PATIENTS.WARNINGS.NAME" /></TableCell>
        <TableCell style={{ width: "15%", fontSize: '10px' }}><FormattedMessage id="PATIENTS.WARNINGS.MEDICAL.RECORD" /></TableCell>
        <TableCell style={{ width: "15%", textAlign: 'center', fontSize: '10px' }}><FormattedMessage id="PATIENTS.WARNINGS.LOCATION" /></TableCell>
        <TableCell style={{ width: "15%", textAlign: 'left', fontSize: '10px' }}><FormattedMessage id="PATIENTS.WARNINGS.SECTOR" /></TableCell>
        <TableCell style={{ width: "5%", textAlign: 'center', fontSize: '10px' }}><FormattedMessage id="PATIENTS.WARNINGS.MEDICINES.DOSAGE" /></TableCell>
        <TableCell style={{ width: "15%", textAlign: 'center', fontSize: '10px' }}><FormattedMessage id="PATIENTS.WARNINGS.MEDICINES.MEDICINE" /></TableCell>
        <TableCell style={{ width: "5%", textAlign: 'center', fontSize: '10px' }}><FormattedMessage id="PATIENTS.WARNINGS.MEDICINES.TIME" /></TableCell>
      </TableRow>
    </TableHead>
  )
}

const SOTableRows = ({ rows, listMedicines, onClose }: any) => {
  const history = useHistory()

  if (!rows || rows.length === 0) return null

  const goToMonitoring = (sectorId: string, monitoringId: string) => {
    if (onClose) onClose()
    const monitoringUrl = `/monitoring/sector/${sectorId}?monitoringId=${monitoringId}`
    console.log(monitoringUrl)
    history.push(monitoringUrl)
  }

  return (
    <TableBody>
      {
        rows.map((obj: IPatientMedicinesDataInNotices, idx: any) => {

          const medicine = listMedicines.find((med: any) => med.id === obj.medicine)?.description ?? obj.medicine

          return (
            <TableRow key={idx} className="cursor-pointer" onClick={
              () => {
                goToMonitoring(obj.sectorId, obj.monitoringId)
              }
            }>
              <TableCell>
                <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg" style={{ textAlign: 'left', fontSize: '10px' }}>
                  {obj.patientName}
                </span>
              </TableCell>
              <TableCell>
                {obj.medicalRecord}
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                {obj.location}
              </TableCell>
              <TableCell style={{ textAlign: 'left', fontSize: '10px' }}>
                {obj.sectorName}
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                {obj.dosage}
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                {medicine}
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                {convertDateTimeToTimeNoSeconds(new Date(obj.time))}
              </TableCell>
            </TableRow>
          )
        })
      }
    </TableBody>
  )
}

function TablePatientWarningsMedicines(props: any) {
  const [medicines, setMedicines] = React.useState<any>(null); // Alterado undefined para null

  React.useEffect(() => {
    getList()
      .then((list) => {
        setMedicines(list);
      })
      .catch((err) => {
        logToSentry(err, 'failed to get list of medicines');
      });
  }, []);

  return (
    medicines && ( // Usando a renderização condicional de forma mais direta
      <div className="table-responsive">
        <Table
          className="table table-head-custom table-vertical-center"
          id="kt_advance_table_widget_1"
          style={{ minWidth: '800px' }}
        >
          <SOTableHeader />
          <SOTableRows
            {...props}
            listMedicines={medicines}
          />
        </Table>
      </div>
    )
  );
}

export default injectIntl((TablePatientWarningsMedicines))