import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Form } from "react-bootstrap";
import { get as _get } from "lodash";

export const FormTextField = (props) => {
  const {
    intl,
    style,
    required,
    disabled,
    xs,
    lg,
    className,
    titleId,
    commentId,
    valueRef,
    overrideValue,
    values,
    type,
    errors,
    touched,
    handleChange,
    handleBlur,
    height,
    maxLength,
    placeholder,
    isTextArea,
  } = props;
  const isInvalid =
    touched && errors && Boolean(touched[valueRef] && errors[valueRef]);
  const isValid =
    touched && errors && Boolean(touched[valueRef] && !errors[valueRef]);

  return (
    <Form.Group
      as={Col}
      xs={xs ?? 12}
      lg={lg ?? 4}
      style={style}
    >
      <Form.Label>
        <FormattedMessage id={titleId} />
      </Form.Label>
      <Form.Control
        style={height ? { height: `${height}px` } : null}
        as={isTextArea ? "textarea" : "input"}
        required={required ?? false}
        disabled={disabled ?? false}
        key={valueRef}
        id={valueRef}
        name={valueRef}
        maxLength={maxLength}
        type={type ?? "text"}
        placeholder={placeholder ?? intl.formatMessage({ id: titleId })}
        value={overrideValue ?? _get(values, valueRef) ?? ""}
        isInvalid={isInvalid ?? false}
        isValid={isValid ?? false}
        onChange={handleChange}
        onBlur={handleBlur}
        className={className}

        autoComplete="nope"
      />
      {commentId && (
        <Form.Label style={{ color: "#999", fontSize: 11 }}>
          <FormattedMessage id={commentId} />
        </Form.Label>
      )}
      <Form.Control.Feedback type="invalid">
        {errors && errors[valueRef]}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
