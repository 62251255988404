import React from "react";
import { Col, Form, InputGroup, Spinner } from "react-bootstrap";
import { get as _get } from "lodash";

export interface RadioButtonsGroupOption {
    id: string;
    label: string;
}

export const FormRadioButtonGroup = (props: any) => {
    const {
        intl,
        style,
        disabled,
        xs,
        lg,
        className,
        plainTitle,
        titleId,
        valueRef,
        values,
        options,
        errors,
        touched,
        handleChange,
        handleBlur,
        isLoading,
        disabledFeedback,
        hidden = false,
    } = props;

    const touchedValue = _get(touched, valueRef);
    const errorValue = _get(errors, valueRef);
    const isInvalid = (touched && errors && Boolean(touchedValue && errorValue)) ?? false;
    const isValid = (touched && errors && Boolean(touchedValue && !errorValue)) ?? false;

    const feedbackEnabled = !disabledFeedback ?? true;

    const emptyOptions = (options ?? []).length === 0;

    const ThisForm = () => (
        <div style={{
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
        }}>
            {options.map((option: RadioButtonsGroupOption) => (
                <Form.Check
                    key={option.id}
                    type="radio"
                    id={option.id}
                    name={valueRef}
                    label={option.label}
                    value={option.id}
                    checked={_get(values, valueRef) == option.id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabled || emptyOptions}
                    isInvalid={isInvalid && feedbackEnabled}
                    isValid={isValid && feedbackEnabled}
                    className={className}
                />
            ))}
        </div>
    );

    return (
        <Form.Group as={Col} xs={xs ?? 12} lg={lg ?? 4} style={{ display: hidden ? "none" : "block", padding: "0", ...style }}>
            <Form.Label>
                {plainTitle ? plainTitle : intl.formatMessage({ id: titleId })}
            </Form.Label>
            {isLoading && (
                <InputGroup>
                    <ThisForm />
                    <InputGroup.Append>
                        <Spinner animation="grow" variant="success" style={{ alignSelf: "center", marginLeft: 8 }} />
                    </InputGroup.Append>
                </InputGroup>
            )}
            {!isLoading && <ThisForm />}
            {feedbackEnabled && (
                <Form.Control.Feedback type="invalid">
                    {errors && errorValue}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};
